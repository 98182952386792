<template>
  <div class="field-group flex flex-col">
    <label for="account_interests">
      Interests
    </label>
    <Panel>
      <p class="mb-4">I’m interested in...</p>
      <TagSearch
        :selected-options="selectedInterests"
        :is-loading="isLoading"
        select-name="account_interests"
        @added-tag="addNewInterest"
        @delete-selected="removeInterest"
      />
    </Panel>
  </div>
</template>

<script setup lang="ts">
import TagSearch from "~/components/global/Search/TagSearch.vue";
import { onMounted, ref, Ref } from "vue";
import Panel from "~/components/global/Panels/Panel.vue";
import { useNoticesStore } from "~/stores/notices";
import { useUserStore } from "~/stores/user";
import { useAccountInterestsComponent } from "~/composables/useAccountInterestsComponent";
const { addInterest, deleteInterest } = useAccountInterestsComponent();

const { setNotice } = useNoticesStore();
const { getAccount } = useUserStore();

const isLoading = ref(false);
const interests = getAccount.interests;

const selectedInterests: Ref<Array<Tag>> = ref([{code: null, name: ''}]);

onMounted(() => {
  if (interests) {
    selectedInterests.value = interests.map((interest) => formatInterest(interest));
  }
});

async function addNewInterest(tag: string) {
  try {
    isLoading.value = true;

    let interest = await addInterest({
      account_id: getAccount.id,
      text: tag
    });

    selectedInterests.value.push(formatInterest(interest));

  } catch (error) {
    setNotice('An error occurred. Please try again.', 'error');
  } finally {
    isLoading.value = false;
  }
}

async function removeInterest(code: number) {
  let interestId = code;

  let index = selectedInterests.value.findIndex((interest) => {
    return interest.code === interestId;
  })

  if (index !== -1) {
    selectedInterests.value.splice(index, 1);

    await deleteInterest(interestId);
  }
}

function formatInterest(interest: AccountInterest) {
  let tag: Tag = {
    name: interest.title,
    code: parseInt(interest.id)
  }

  return tag;
}

</script>
