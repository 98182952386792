import { useAccessToken } from "~/composables/useAccessToken";

export function useAccountInterestsComponent() {

  const {getAccessToken} = useAccessToken();


  const fetchInterests = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.get('/interests', {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      console.error('Error fetching interests:', error);
    }
  };

  const addInterest = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/interests', {
        account_id: '',
        ...params
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const deleteInterest = async (id: number) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.delete('/interests/' + id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      console.error('Error deleting interest:', error);
    }
  }

  return {
    fetchInterests,
    addInterest,
    deleteInterest
  };
}
